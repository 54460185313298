import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseViewModel }              from 'base-view-model';
import { ServiceTypesRepository }     from './services/repository';

@inject(AppContainer, ServiceTypesRepository)
export class ListServiceTypes extends BaseViewModel {

    headerTitle = 'listing.wemake.service-types';
    listingId   = 'wemake-service-types-listing';

    @bindable newRecordRoute = 'wemake.service-types.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.service-types.manage',
            'wemake.service-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.wemake.service-type',
            repository: this.repository,
            edit:       'wemake.service-types.edit',
            destroy:    'wemake.service-types.delete',
            actions:    [
                {
                    tooltip: 'form.button.view',
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'wemake.service-types.view',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'acronym',
                    name:  'service_types.acronym',
                    title: 'form.field.acronym',
                },
                {
                    data:  'name',
                    name:  'service_types.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }
}
