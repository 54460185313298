import { Translation } from 'modules/wemake/models/translation';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Translation}
     */
    model(viewModel) {
        let model = new Translation();

        viewModel.languages.forEach((language) => model[`text_${language.locale}`] = null);

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        let isSystemReserved = viewModel.appContainer.authenticatedUser.isSystemReserved();

        this.group = {
            type:       'text',
            key:        'group',
            label:      'form.field.group',
            size:       4,
            attributes: {
                disabled: !isSystemReserved,
            },
        };

        this.key = {
            type:       'text',
            key:        'key',
            label:      'form.field.key',
            size:       8,
            attributes: {
                disabled: !isSystemReserved,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('wemake.translations.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitAndContinueButton = {
            type:       'submit',
            label:      'form.button.save-and-continue',
            action:     () => viewModel.submit(false),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.submitAndContinueButton,
            ],
        };

        let schema = [
            [this.group, this.key],
        ];

        viewModel.languages.forEach((language) => {
            schema.push([{
                type:  'textarea',
                key:   `text_${language.locale}`,
                label: language.name,
                size:  12,
            }]);
        });

        schema.push([this.buttons]);

        return schema;
    }

}
