import { inject }                    from 'aurelia-framework';
import { ServiceType }               from 'modules/wemake/models/service-type';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(BooleanStatusesRepository)
export default class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param booleanStatusesRepository
     */
    constructor(booleanStatusesRepository) {
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ServiceType}
     */
    model() {
        let model = new ServiceType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.acronym = {
            type:       'text',
            key:        'acronym',
            label:      'form.field.acronym',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.description = {
            type:       'textarea',
            key:        'description',
            label:      'form.field.description',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('wemake.service-types.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.acronym, this.name],
            [this.description],
            [this.status_id],
            [this.buttons],
        ];
    }

}
