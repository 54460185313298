import { bindable, inject }       from 'aurelia-framework';
import { AppContainer }           from 'resources/services/app-container';
import { BaseListViewModel }      from 'base-list-view-model';
import { TranslationsRepository } from 'modules/wemake/translations/services/repository';

@inject(AppContainer, TranslationsRepository)
export class ListTranslations extends BaseListViewModel {

    listingId = 'wemake-translations-listing';

    @bindable headerTitle    = 'listing.wemake.translations';
    @bindable newRecordRoute = 'wemake.translations.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        if (!this.appContainer.authenticatedUser.isSystemReserved()) {
            this.newRecordRoute = null;
        }

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.translations.manage',
            'wemake.translations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        let isSystemReserved = this.appContainer.authenticatedUser.isSystemReserved();

        this.datatable = {
            resource:        'resource.wemake.translation',
            repository:      this.repository,
            edit:            'wemake.translations.edit',
            destroy:         isSystemReserved ? 'wemake.translations.destroy' : false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      isSystemReserved,
            destroySelected: isSystemReserved,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'id',
                    name:  'translations.id',
                    title: '#',
                },
                {
                    data:  'group',
                    name:  'translations.group',
                    title: 'form.field.group',
                },
                {
                    data:  'key',
                    name:  'translations.key',
                    title: 'form.field.key',
                },
                {
                    data:  'text',
                    name:  'translation_lines.text',
                    title: 'form.field.text',
                },
            ],
        };
    }
}
