import { inject, PLATFORM }       from 'aurelia-framework';
import { AppContainer }           from 'resources/services/app-container';
import { BaseFormViewModel }      from 'base-form-view-model';
import { TranslationsRepository } from 'modules/wemake/translations/services/repository';
import { LanguagesRepository }    from 'modules/administration/languages/services/repository';
import { FormSchema }             from 'modules/wemake/translations/create/form-schema';

@inject(AppContainer, TranslationsRepository, LanguagesRepository, FormSchema)
export class CreateTranslation extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'wemake.translations.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    languages = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param languagesRepository
     * @param formSchema
     */
    constructor(appContainer, repository, languagesRepository, formSchema) {
        super(appContainer);

        this.repository          = repository;
        this.languagesRepository = languagesRepository;
        this.formSchema          = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.translations.manage',
            'wemake.translations.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        return this.languagesRepository.all()
            .then((response) => this.languages = response)
            .then(() => {
                this.initialModel = this.formSchema.model(this);
                this.model        = this.formSchema.model(this);
                this.schema       = this.formSchema.schema(this);
            });
    }

    /**
     * Submits view form
     */
    submit(redirectToList = true) {
        this.alert = null;

        this.repository
            .create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    if (redirectToList) {
                        this.initialModel.assign(this.model);

                        this.redirectToRoute('wemake.translations.index');
                    } else {
                        this.resetForm();
                    }
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(error => console.log(error));
    }

}
